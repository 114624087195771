import { Trans, defineMessage } from '@lingui/macro'
import BookFreeClassButton from '@components/common/book-free-class-btn'
import MsgTrans from '@components/common/i18n/message-trans'
import {
  AcademicCap,
  FileWithBadge,
  MonitorWithInstructor,
} from '@components/common/icons/custom-icons'
import {
  Award,
  FileText,
  Monitor,
  Package,
  Phone,
  User,
  Users,
  Video,
} from 'react-feather'
import { BookOpenIcon } from '@heroicons/react/24/outline'

const MATH_BENIFITS = [
  {
    icon: (
      <>
        <AcademicCap className="text-white opacity-50 mx-auto w-12 h-12" />
      </>
    ),
    description: defineMessage({
      message: `Aligned with Common Core, state standards, and Advanced Placement (AP) curricula.`,
    }),
    background: '#9B51E0',
  },
  {
    icon: (
      <>
        <BookOpenIcon className="text-white opacity-50 mx-auto w-12 h-12" />
      </>
    ),
    description: defineMessage({
      message: `Homework assistance, enhanced learning, test preparation, and beyond.`,
    }),
    background: '#F2994A',
  },
  {
    icon: (
      <>
        <Package className="text-white opacity-50 mx-auto w-12 h-12" />
      </>
    ),
    description: defineMessage({
      message: `Scientific teaching methods based on Bloom's Taxonomy, BIDE, and STEAM principles.`,
    }),
    background: '#6FCF97',
  },
  {
    icon: (
      <>
        <FileWithBadge className="text-white opacity-50 mx-auto w-12 h-12" />
      </>
    ),
    description: defineMessage({
      message: `STEM.org accredited certificate for the course`,
    }),
    background: '#2F80ED',
  },
  {
    icon: (
      <>
        <Phone className="text-white opacity-50 mx-auto w-12 h-12" />
      </>
    ),
    description: defineMessage({
      message: `Unlimited doubt sessions and dedicated support available 24x7`,
    }),
    background: '#FFBA07',
  },
  {
    icon: (
      <>
        <Award className="text-white opacity-50 mx-auto w-12 h-12" />
      </>
    ),
    description: defineMessage({
      message: `Get assured 100% moneyback guarantee`,
    }),
    background: '#FF7E3B',
  },
  {
    icon: (
      <>
        <FileText className="text-white opacity-50 mx-auto w-12 h-12" />
      </>
    ),
    description: defineMessage({
      message: `Worksheets and quizzes after every class.`,
    }),
    background: '#ED6CEF',
  },
  {
    icon: (
      <>
        <Monitor className="text-white opacity-50 mx-auto w-12 h-12" />
      </>
    ),
    description: defineMessage({
      message: `Gamified learning dashboard with projects & quizzes`,
    }),
    background: '#3DD771',
  },
  {
    icon: (
      <>
        <Video className="text-white opacity-50 mx-auto w-12 h-12" />
      </>
    ),
    description: defineMessage({
      message: `Lifetime access to all class recordings and class notes`,
    }),
    background: '#BB6BD9',
  },
  {
    icon: (
      <>
        <Users className="text-white opacity-50 mx-auto w-12 h-12" />
      </>
    ),
    description: defineMessage({
      message: `Regular PTM: parents and teachers meeting`,
    }),
    background: '#EB5757',
  },
  {
    icon: (
      <>
        <User className="text-white opacity-50 mx-auto w-12 h-12" />
      </>
    ),
    description: defineMessage({
      message: `Personalized learning: only one teacher, one student per class.`,
    }),
    background: '#6FCF97',
  },
  {
    icon: (
      <>
        <MonitorWithInstructor className="text-white opacity-50 mx-auto w-12 h-12" />
      </>
    ),
    description: defineMessage({
      message: `Handpicked math instructor as per your need`,
    }),
    background: '#F2C94C',
  },
]

const MathBenifits = () => {
  return (
    <div
      className="py-10 lg:py-16 overflow-x-hidden mb-5 lg:mb-0"
      style={{
        background: `url('/images/home/founders/box-bg.png') repeat-x #F4FAFF`,
      }}
    >
      <h2 className="px-5 relative text-grey font-700 text-2xl max-w-6xl mx-auto leading-tight lg:text-4xl mb-5 text-center">
        <Trans>
          Experience the difference of our training program with the many unique
          benefits in Math education
        </Trans>
      </h2>
      <div
        className="flex justify-center flex-wrap mx-auto py-6 lg:py-8"
        style={{ maxWidth: '2048px' }}
      >
        {MATH_BENIFITS.map((benefit, i) => (
          <div
            key={i}
            className="w-full md:w-1/3 lg:w-1/4 p-8"
            style={{ background: benefit.background }}
          >
            {benefit.icon}
            <p className="pt-6 font-600 text-lg lg:text-xl text-center">
              <MsgTrans msg={benefit.description} />
            </p>
          </div>
        ))}
      </div>
      <div className="flex justify-center lg:pt-6 px-4">
        <BookFreeClassButton size="xl" query="?course=math-trial-class" />
      </div>
    </div>
  )
}

export default MathBenifits
