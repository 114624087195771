import { FastlyImage } from '@components/common/image'
import Ratings from '@components/common/ratings'
import HeroPhoneNumber from '@components/home/hero-phone-numer'
import PhoneInputContainer from '@containers/phone-input'
import { t, Trans } from '@lingui/macro'
import { useEffect, useRef } from 'react'
import PriceSlotsOverview from '@components/home/price-slots-overview'
import StudentCountriesOverview from '@components/home/student-countries-overview'

export const MATH_CLASS_DETAILS = [
  {
    title: 'Age group',
    description: <>5-17 years</>,
  },
  {
    title: 'Program delivery',
    description: <>Online</>,
  },
  {
    title: 'Batch size',
    description: <>1-1 Classes</>,
  },
  {
    title: 'Certification by',
    description: <>STEM.org</>,
  },
]

type Props = {
  localeCountryCode?: string
}
/** i18n support added */
const FirstFold = ({ localeCountryCode }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Auto-focus on phone input field on page load
    document.getElementById('phone').focus()
  }, [])

  return (
    <>
      <div
        className="relative flex justify-between px-5 md:px-3 pt-8 pb-10 overflow-visible lg:pb-20 lg:rounded-b-lg"
        style={{ background: '#FFEDEB' }}
        ref={containerRef}
      >
        <div className="relaive container flex flex-col mx-auto z-10 lg:flex-row">
          <div className="z-10 flex flex-col items-center space-y-5 text-center lg:items-start lg:text-left lg:mt-10">
            <Ratings isDarkText />
            <h1 className="max-w-2xl text-2xl leading-tight text-center font-600 lg:text-5xl lg:text-left">
              <Trans>
                <span className="underlined-text">Learn Algebra, Geometry</span>{' '}
                and More From the Best Math Tutors
              </Trans>
            </h1>
            {/* phone number input */}
            <div className="text-left w-full sm:w-auto">
              <PhoneInputContainer
                buttonText={t`Try a free lesson`}
                isInHomePage
                countryCode={localeCountryCode}
                courseQuery="math-trial-class"
              />
              <div className="mt-3">
                <PriceSlotsOverview />
              </div>
            </div>
            <StudentCountriesOverview isTextWhite={false} />
            <div>
              <HeroPhoneNumber />
            </div>
          </div>
          <div className="relative flex flex-col items-center justify-center mx-auto">
            <FastlyImage
              src="/images/courses/math/hero-imag-1.png"
              webpSrc="/images/courses/math/hero-imag-1.webp"
              width={381}
              height={415}
              alt={t`Children learning Math through online Math classes`}
              priority
              loading="eager"
            />
          </div>
        </div>
      </div>
      <div className="container mx-auto relative max-w-3xl text-white px-4 lg:px-12 -mt-12">
        <div
          className="rounded-lg flex flex-wrap md:flex-no-wrap justify-evenly md:px-6 lg:px-12 p-4 lg:py-6 gap-y-4 md:gap-x-4 items-start text-white mb-1 text-sm"
          style={{ backgroundColor: '#9B51E0' }}
        >
          {MATH_CLASS_DETAILS.map((details, index) => (
            <>
              <div key={index} className="w-1/2 md:w-auto">
                <p>{details.title}</p>
                <p className="font-600 text-lg">{details.description}</p>
              </div>
              {index < MATH_CLASS_DETAILS.length - 1 && (
                <span
                  className="hidden md:block bg-blue-100 bg-opacity-50 h-10 mt-1"
                  style={{ width: '1px' }}
                />
              )}
            </>
          ))}
        </div>
      </div>
    </>
  )
}

export default FirstFold
