import { ArrowRight } from 'react-feather'
import { FastlyImage } from '@components/common/image'
import Link from 'next/link'
import { Trans } from '@lingui/macro'

const content = [
  {
    text: 'Early Education',
    imgpath: '/images/courses/math/math-help2',
  },
  { text: 'Test Prep', imgpath: '/images/courses/math/math-help3' },
  { text: 'Homework Help', imgpath: '/images/courses/math/math-help4' },
]

const MathHelp = () => {
  return (
    <div
      className="py-10 lg:py-16 overflow-x-hidden"
      style={{
        background: `#FFF7F3`,
      }}
    >
      <div
        className="flex justify-center flex-wrap mx-auto py-6 lg:py-8"
        style={{ maxWidth: '2048px' }}
      >
        <div className="relative shadow-sm">
          <FastlyImage
            src="/images/courses/math/math-help1.png"
            webpSrc="/images/courses/math/math-help1.webp"
            width={624}
            height={683}
            alt="Math Class for kids"
          />
          <div
            className="p-12 absolute top-0 left-0 w-4/5 shadow-sm"
            style={{
              background: `#FFF7F3`,
            }}
          >
            <h2 className="text-3xl lg:text-6xl font-700">
              <Trans>Looking for a specific type of Math help?</Trans>
            </h2>
            <p>
              <Trans>We’ve got it handled.</Trans>
            </p>
          </div>
        </div>

        <div className="flex justify-between text-2xl lg:text-5xl font-700 m-4 md:m-0 pt-3 lg:pt-0 flex-col">
          {content.map((content, i) => (
            <Link
              key={i}
              href="/register/?course=math-trial-class"
              prefetch={false}
            >
              <a className="flex justify-between items-center space-x-3 w-100 max-w-md my-4 mx-2 lg:mx-4 shadow-md hover:shadow-lg bg-white rounded-lg pr-5 cursor-pointer">
                <FastlyImage
                  src={`${content.imgpath}.png`}
                  webpSrc={`${content.imgpath}.webp`}
                  width={271}
                  height={215}
                  alt={content.text}
                />
                <div className="w-56">
                  <p className="block xl: w-48">{content.text}</p>
                </div>
                <ArrowRight className="text-orange h-12 w-12" />
              </a>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default MathHelp
