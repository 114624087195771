import { FastlyImage } from '@components/common/image'
import { Trans } from '@lingui/macro'

import BookFreeClassButton from '@components/common/book-free-class-btn'

const WhyCodingal = () => {
  return (
    <div
      className="flex flex-col relative items-center pt-10 lg:pt-16 px-5 overflow-x-hidden"
      style={{
        background: `url('/images/courses/math/orange-box.png') repeat-x #FCF4E4`,
      }}
    >
      <h2 className="relative text-grey font-700 text-2xl max-w-2xl mx-6 text-center leading-tight lg:text-4xl mb-5">
        <Trans>
          Our teaching methods and philosophy inspire students to fall in love
          with math.
        </Trans>
      </h2>
      <div className="hidden md:block">
        <FastlyImage
          src="/images/courses/math/why-codingal.png"
          webpSrc="/images/courses/math/why-codingal.webp"
          width={1163}
          height={700}
          alt="Why Codingal Math Class"
        />
      </div>
      <div className="visible md:hidden">
        <FastlyImage
          src="/images/courses/math/why-codingal-mobile.png"
          webpSrc="/images/courses/math/why-codingal-mobile.webp"
          width={423}
          height={1074}
          alt="Why Codingal Math Class"
        />
      </div>
      <div className="flex justify-center -mt-2 md:-mt-8 z-10">
        <BookFreeClassButton size="xl" query="?course=math-trial-class" />
      </div>
    </div>
  )
}

export default WhyCodingal
