import useDetectCountry from '@hooks/useDetectCountry'
import { COUNTRIES_WITH_PHYSICAL_ADDRESS } from '@lib/constants/geo-lp'
import { Phone } from 'react-feather'

const HeroPhoneNumber = () => {
  const { countryCode } = useDetectCountry()

  // Excluding the first two countries US and CA
  // as the design of hero section for those pages are different than the others
  const countryCodes = Object.keys(COUNTRIES_WITH_PHYSICAL_ADDRESS)
  if (!countryCodes.includes(countryCode)) return null

  return (
    <div className="flex items-start lg:items-center lg:justify-center space-x-2 mt-6">
      <div className="bg-yellow-200 rounded-full p-1">
        <Phone
          size={15}
          fill="white"
          className="text-white"
          strokeWidth={0.1}
        />
      </div>
      <p>
        Call us at <b>{COUNTRIES_WITH_PHYSICAL_ADDRESS?.[countryCode]?.ph}</b>{' '}
        for help & questions.
      </p>
    </div>
  )
}
export default HeroPhoneNumber
