import MsgTrans from '@components/common/i18n/message-trans'
import { Trans, defineMessage } from '@lingui/macro'
import Link from 'next/link'

const CONTENT = [
  {
    title: defineMessage({
      message: `Has a passion for math and seeks more challenging material`,
    }),
    description: defineMessage({
      message: `Codingal offers your child the chance to excel beyond their current grade level.`,
    }),
  },
  {
    title: defineMessage({
      message: `Is comfortable but could use a bit of help`,
    }),
    description: defineMessage({
      message: `Codingal can help your child excel at their current grade level and discover the joy in math.`,
    }),
  },
  {
    title: defineMessage({ message: `Is frustrated or lacks engagement` }),
    description: defineMessage({
      message: `Mathnasium’s math tutors will assist your child in catching up with their school curriculum.`,
    }),
  },
]

const MathEngagementFold = () => {
  return (
    <div className="container mx-auto flex flex-col space-y-10 leading-tight py-16 text-grey px-5">
      <div className="text-center">
        <h2 className="text-2xl lg:text-4xl font-600 mb-4">
          <Trans>
            How does your child currently feel about math activities and
            lessons?
          </Trans>
        </h2>
      </div>

      <div className="flex flex-wrap justify-center gap-5 xl:gap-10">
        {CONTENT.map((content, i) => (
          <div
            key={i}
            className="flex flex-col justify-between shadow hover:shadow-lg transition-all duration-200 rounded-md w-96 max-w-full"
          >
            <div className="flex flex-col space-y-3 p-5 lg:h-48">
              <h3 className="text-xl lg:text-2xl leading-tight font-700">
                <MsgTrans msg={content.title} />
              </h3>
              <p className="lg:h-10">
                {' '}
                <MsgTrans msg={content.description} />
              </p>
            </div>
            <Link href="/register/?course=math-trial-class" prefetch={false}>
              <a className="btn btn-orange py-3 text-lg text-center mx-8 mb-6">
                <Trans>Try a free lesson</Trans>
              </a>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MathEngagementFold
