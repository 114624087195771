import React from 'react'
import classNames from 'classnames'
import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { Trans } from '@lingui/macro'

type Props = {
  activeTabLabel: string
  tabLabel: LinguiJSMessageDescriptor
  handleClick: () => void
}

const Tab = ({ activeTabLabel, tabLabel, handleClick }: Props) => (
  <div
    className={classNames(
      'text-center rounded-md m-1 py-1 md:py-2 px-2 lg:px-6 lg:text-lg cursor-pointer',
      {
        'border text-orange border-orange-300 bg-orange-200 font-600':
          activeTabLabel === tabLabel?.id,
      }
    )}
    onClick={() => handleClick()}
  >
    <span>
      <Trans id={tabLabel?.id} values={tabLabel?.values} />
    </span>
  </div>
)

export default Tab
