import { FastlyImage } from '@components/common/image'

const Overview = () => {
  return (
    <div className="container mx-auto flex lg:flex-row flex-col items-center lg:space-x-12 leading-tight py-16 text-grey px-5">
      <div className="mb-5 lg:mb-0 lg:w-full">
        <FastlyImage
          src="/images/courses/math/overview.png"
          webpSrc="/images/courses/math/overview.webp"
          height={374}
          width={397}
          alt="Student Learning Math Course Class Online"
        />
      </div>
      <div className="mx-0 lg:pl-5 space-y-4 lg:text-lg font-500 lg:font-600 xl:leading-relaxed">
        <p>
          Explore the exciting world of math with Codingal’s easy-to-use
          learning platform, offering STEM accredited courses tailored to
          various global educational boards.
        </p>
        <p>
          Our math classes are designed to make learning fun and engaging,
          helping students improve their problem-solving skills and build a
          strong foundation. With personalized attention from our expert
          teachers and interactive classes, every student can thrive.
        </p>
        <p>
          Our handy dashboard makes it easy to track progress, schedule classes,
          and find class notes. Plus, join our community for access to special
          resources, worldwide competitions, and masterclasses. Start your math
          adventure with Codingal today!
        </p>
      </div>
    </div>
  )
}

export default Overview
