import BookFreeClassBtn from '@components/common/book-free-class-btn'
import { FastlyImage } from '@components/common/image'
import Tabs from '@components/common/tabs'
import { defineMessage, Trans } from '@lingui/macro'
import { useState } from 'react'

export const GRADE_CONTENT = {
  grade1: [
    {
      imagePath: 'grade1-3',
      lesson: [
        'Number System',
        'Number Operations',
        'Fractions',
        'Time & Measurement',
        'Geometry',
        'Data Handling',
      ],
    },
  ],
  grade2: [
    {
      imagePath: 'grade1-3',
      lesson: [
        'Number System',
        'Number Operations',
        'Fractions',
        'Time & Measurement',
        'Geometry',
        'Data Handling',
      ],
    },
  ],
  grade3: [
    {
      imagePath: 'grade1-3',
      lesson: [
        'Number System',
        'Number Operations',
        'Fractions',
        'Time & Measurement',
        'Geometry',
        'Data Handling',
      ],
    },
  ],
  grade4: [
    {
      imagePath: 'grade4-5',
      lesson: [
        'Number System',
        'Number Operations',
        'Factors & Multiples',
        'Fractions & Decimals',
        'Time, Money & Measurement',
        'Geometry & Patterns',
        'Data Handling',
      ],
    },
  ],
  grade5: [
    {
      imagePath: 'grade4-5',
      lesson: [
        'Number System',
        'Number Operations',
        'Factors & Multiples',
        'Fractions & Decimals',
        'Time, Money & Measurement',
        'Geometry & Patterns',
        'Data Handling',
      ],
    },
  ],
  grade6: [
    {
      imagePath: 'grade6-8',
      lesson: [
        'Number System',
        'Number Operations',
        'Fractions',
        'Time & Measurement',
        'Geometry',
        'Data Handling',
      ],
    },
  ],
  grade7: [
    {
      imagePath: 'grade6-8',
      lesson: [
        'Number System',
        'Number Operations',
        'Fractions',
        'Time & Measurement',
        'Geometry',
        'Data Handling',
      ],
    },
  ],
  grade8: [
    {
      imagePath: 'grade6-8',
      lesson: [
        'Number System',
        'Number Operations',
        'Fractions',
        'Time & Measurement',
        'Geometry',
        'Data Handling',
      ],
    },
  ],
  grade9: [
    {
      imagePath: 'grade9-12',
      lesson: ['Numbers', 'Algebra', 'Geometry', 'Mensuration', 'Data'],
    },
  ],
  grade10: [
    {
      imagePath: 'grade9-12',
      lesson: ['Numbers', 'Algebra', 'Geometry', 'Mensuration', 'Data'],
    },
  ],
  grade11: [
    {
      imagePath: 'grade9-12',
      lesson: ['Numbers', 'Algebra', 'Geometry', 'Mensuration', 'Data'],
    },
  ],
  grade12: [
    {
      imagePath: 'grade9-12',
      lesson: ['Numbers', 'Algebra', 'Geometry', 'Mensuration', 'Data'],
    },
  ],
}

const GRADES = [
  {
    title: defineMessage({ message: `Grade 1` }),
    content: GRADE_CONTENT['grade1'],
  },
  {
    title: defineMessage({ message: `Grade 2` }),
    content: GRADE_CONTENT['grade2'],
  },
  {
    title: defineMessage({ message: `Grade 3` }),
    content: GRADE_CONTENT['grade3'],
  },
  {
    title: defineMessage({ message: `Grade 4` }),
    content: GRADE_CONTENT['grade4'],
  },
  {
    title: defineMessage({ message: `Grade 5` }),
    content: GRADE_CONTENT['grade5'],
  },
  {
    title: defineMessage({ message: `Grade 6` }),
    content: GRADE_CONTENT['grade6'],
  },
  {
    title: defineMessage({ message: `Grade 7` }),
    content: GRADE_CONTENT['grade7'],
  },
  {
    title: defineMessage({ message: `Grade 8` }),
    content: GRADE_CONTENT['grade8'],
  },
  {
    title: defineMessage({ message: `Grade 9` }),
    content: GRADE_CONTENT['grade9'],
  },
  {
    title: defineMessage({ message: `Grade 10` }),
    content: GRADE_CONTENT['grade10'],
  },
  {
    title: defineMessage({ message: `Grade 11` }),
    content: GRADE_CONTENT['grade11'],
  },
  {
    title: defineMessage({ message: `Grade 12` }),
    content: GRADE_CONTENT['grade12'],
  },
]

export const TabbedCurriculum = () => {
  const defaultActiveTabLabel = 'Grade 6'
  const [activeTabLabel, setActiveTabLabel] = useState(defaultActiveTabLabel)
  return (
    <div className="relative w-full px-5 md:px-3 pb-5 overflow-hidden lg:px-0 lg:pb-10">
      <h2
        id="curriculum"
        className="md:px-5 pt-12 pb-5 text-2xl leading-tight text-center text-grey lg:text-4xl font-700 max-w-6xl mx-auto"
      >
        <Trans>A globally recognized class curriculum that opens doors</Trans>
      </h2>
      <p className="text-xl leading-tight max-w-6xl lg:mx-auto px-5 pb-8 text-center text-grey">
        <Trans>
          Our curriculum is fully aligned with the U.S. Common Core standards
        </Trans>
      </p>
      <Tabs
        activeTabLabel={activeTabLabel}
        setActiveTabLabel={setActiveTabLabel}
      >
        {GRADES.map(({ title, content }) => (
          <div data-label={title} key={title?.id}>
            <div
              className={`flex justify-center transition duration-500 ease-in ${
                activeTabLabel === title?.id ? 'flex' : 'hidden'
              }`}
            >
              {content.map((gradeContent, index) => (
                <div
                  className="flex flex-col md:flex-row py-10 gap-10"
                  key={index}
                >
                  <FastlyImage
                    src={`/images/courses/math/${gradeContent.imagePath}.png`}
                    webpSrc={`/images/courses/math/${gradeContent.imagePath}.webp`}
                    width={353}
                    height={306}
                    alt="Math Curriculum"
                  />
                  <div className="text-lg text-left space-y-4">
                    {gradeContent.lesson.map((lesson, index) => (
                      <p key={index}>
                        <span className="rounded-full bg-blue-200 text-white text-sm inline-block w-5 h-5 font-500 text-center mr-2">
                          {index + 1}
                        </span>
                        {lesson}
                      </p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </Tabs>
      <div className="flex flex-col justify-center py-6 space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
        <BookFreeClassBtn size="xl" query="?course=math-trial-class" />
      </div>
    </div>
  )
}

export default TabbedCurriculum
