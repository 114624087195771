import Tab from '@components/common/tabs/tab'
import { LinguiJSMessageDescriptor } from '@lib/types/common'

type Props = {
  activeTabLabel: string
  setActiveTabLabel: React.Dispatch<React.SetStateAction<string>>
  card?: JSX.Element
  children: JSX.Element[]
  shouldShowTabs?: boolean
}

const Tabs = ({
  activeTabLabel,
  setActiveTabLabel,
  card,
  children: curriculums,
  shouldShowTabs = true,
}: Props): JSX.Element => {
  const chunkArray = (array, chunkSize) => {
    const result = []
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize))
    }
    return result
  }

  const curriculumChunks = chunkArray(curriculums, 3)

  return (
    <div className="tabs text-center w-full lg:mx-auto lg:max-w-6xl lg:px-10">
      {shouldShowTabs && (
        <>
          <div className="hidden md:inline">
            <div className="inline-flex rounded-md bg-white border border-grey-400 flex-wrap justify-between lg:justify-center mb-2">
              {curriculums.slice(0, 7).map((curriculum) => {
                const label = curriculum.props[
                  'data-label'
                ] as LinguiJSMessageDescriptor
                return (
                  <Tab
                    activeTabLabel={activeTabLabel}
                    key={label?.id}
                    tabLabel={label}
                    handleClick={() => setActiveTabLabel(label?.id)}
                  />
                )
              })}
            </div>

            <div className="inline-flex rounded-md bg-white border border-grey-400 flex-wrap justify-between lg:justify-center">
              {curriculums.slice(7, curriculums.length).map((curriculum) => {
                const label = curriculum.props[
                  'data-label'
                ] as LinguiJSMessageDescriptor
                return (
                  <Tab
                    activeTabLabel={activeTabLabel}
                    key={label?.id}
                    tabLabel={label}
                    handleClick={() => setActiveTabLabel(label?.id)}
                  />
                )
              })}
            </div>
          </div>

          <div className="md:hidden inline">
            {curriculumChunks.map((chunk, chunkIndex) => (
              <div
                key={chunkIndex}
                className="inline-flex rounded-md bg-white border border-grey-400 flex-wrap justify-between lg:justify-center mb-2 w-80 mx-3 max-w-full"
              >
                {chunk.map((curriculum) => {
                  const label = curriculum.props[
                    'data-label'
                  ] as LinguiJSMessageDescriptor
                  return (
                    <Tab
                      activeTabLabel={activeTabLabel}
                      key={label?.id}
                      tabLabel={label}
                      handleClick={() => setActiveTabLabel(label?.id)}
                    />
                  )
                })}
              </div>
            ))}
          </div>
        </>
      )}

      <div className="tab-content">
        {curriculums.map((curriculum) => {
          return curriculum.props.children
        })}
      </div>
      <div>{card}</div>
    </div>
  )
}

export default Tabs
